/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminAccessInfo } from '../models/AdminAccessInfo';
import type { Author } from '../models/Author';
import type { EmailOrUsernameExists } from '../models/EmailOrUsernameExists';
import type { Pagination } from '../models/Pagination';
import type { PlatformID } from '../models/PlatformID';
import type { ProfileActionPatch } from '../models/ProfileActionPatch';
import type { User } from '../models/User';
import type { UserActionPatch } from '../models/UserActionPatch';
import type { UserRole } from '../models/UserRole';
import type { UserSessionDeleteResult } from '../models/UserSessionDeleteResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UserService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get a list of ignored userIDs.
     * Return a list of ignored userIDs, filtered by the authenticated user.
     *
     * - Allowed roles: user, moderator, editor, admin
     * @returns number Success
     * @throws ApiError
     */
    public listIgnoredUser({
        cache,
    }: {
        cache?: boolean,
    }): CancelablePromise<Array<number>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/ignore',
            query: {
                'cache': cache,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Ignore multiple users by userIDs.
     * Add multiple userIDs to the list of ignored users.
     *
     * - Allowed roles: user, moderator, editor, admin
     * @returns number Created
     * @throws ApiError
     */
    public addIgnoredUser({
        requestBody,
    }: {
        requestBody: Array<number>,
    }): CancelablePromise<Array<number>> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/user/ignore',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Delete an ignored user by userID
     * Delete a userID from the list of ignored users.
     *
     * - Allowed roles: user, moderator, editor, admin
     * @returns void
     * @throws ApiError
     */
    public deleteIgnoredUser({
        userId,
    }: {
        /**
         * userID of the user that should be removed from the current user's ignored user list
         */
        userId: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/user/ignore/{userID}',
            path: {
                'userID': userId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Get user list
     * Get a list of all users
     *
     * - Allowed roles: moderator, editor, admin
     * @returns any Success
     * @throws ApiError
     */
    public listUser({
        search,
        page = 1,
        perPage = 20,
        userRoles,
        cache,
    }: {
        search?: string,
        /**
         * current page
         */
        page?: number,
        /**
         * amount of items per page
         */
        perPage?: number,
        /**
         * filter on userRole
         */
        userRoles?: Array<UserRole>,
        cache?: boolean,
    }): CancelablePromise<{
        pagination?: Pagination;
        data?: Array<User>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user',
            query: {
                'search': search,
                'page': page,
                'perPage': perPage,
                'userRoles': userRoles,
                'cache': cache,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Get user-author by slug.
     * Get user-author by slug.
     * @returns Author Success
     * @throws ApiError
     */
    public getAuthorByCustomSlugUser({
        slug,
    }: {
        /**
         * Get user by customSlug
         */
        slug: string,
    }): CancelablePromise<Author> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/author/{slug}',
            path: {
                'slug': slug,
            },
        });
    }
    /**
     * Get user profile
     * - Allowed roles: user, moderator, editor, admin
     * @returns User Success
     * @throws ApiError
     */
    public getProfileByIdUser(): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/profile',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Update personal profile data
     * Update personal profile information
     *
     * - Allowed roles: user, moderator, editor, admin
     * @returns User Success
     * @throws ApiError
     */
    public patchProfileUser({
        requestBody,
    }: {
        requestBody: ProfileActionPatch,
    }): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/user/profile',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @deprecated
     * **Deprecated**: Should only be used by dark-admin.
     *
     * Get information to authenticate a user in dark-admin.
     *
     * - Allowed roles: moderator, editor, admin
     * @returns AdminAccessInfo Success
     * @throws ApiError
     */
    public getAdminAccessInfoUser(): CancelablePromise<AdminAccessInfo> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/admin-access/info',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Get all tenants that the logged in user is connected to.
     *
     * - Allowed roles: user, moderator, editor, admin
     * @returns any Success
     * @throws ApiError
     */
    public getUserTenantsUser(): CancelablePromise<Array<Record<string, any>>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/tenant',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Associate user with a tenant
     * - Allowed roles: user, moderator, editor, admin
     * @returns void
     * @throws ApiError
     */
    public associateUserWithTenantUser({
        tenantId,
    }: {
        tenantId: PlatformID,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/tenant/{tenantID}/associate-user',
            path: {
                'tenantID': tenantId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Get user by id.
     * Get user by id.
     *
     * - Allowed roles: moderator, editor, admin
     * @returns User Success
     * @throws ApiError
     */
    public getByIdUser({
        userId,
        editionId,
    }: {
        /**
         * Get user by id
         */
        userId: number,
        /**
         * Used to get the `author` property.
         */
        editionId?: number,
    }): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/{userID}',
            path: {
                'userID': userId,
            },
            query: {
                'editionID': editionId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Update entry
     * Update a specific user
     *
     * - Allowed roles: moderator, editor, admin
     * @returns User Success
     * @throws ApiError
     */
    public patchUser({
        userId,
        requestBody,
        editionId,
    }: {
        userId: number,
        requestBody: UserActionPatch,
        /**
         * Required when patching the `author` property.
         */
        editionId?: number,
    }): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/user/{userID}',
            path: {
                'userID': userId,
            },
            query: {
                'editionID': editionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Delete a user
     * Deletes and returns 204 or 404 depending if the request succeeds.
     *
     * If this user has an active Supertokens access token, session verification will still succeed until their access token expires. After that, they will be logged out, since the session refresh will fail.
     *
     * - Allowed roles: user, moderator, editor, admin
     * @returns void
     * @throws ApiError
     */
    public deleteUser({
        userId,
    }: {
        /**
         * Will be used to remove a user
         */
        userId: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/user/{userID}',
            path: {
                'userID': userId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Check if a username exists.
     * @returns boolean Success
     * @throws ApiError
     */
    public usernameExistsUser({
        username,
    }: {
        username: string,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/username/exists',
            query: {
                'username': username,
            },
        });
    }
    /**
     * Check if an email exists.
     * @returns boolean Success
     * @throws ApiError
     */
    public emailExistsUser({
        email,
    }: {
        email: string,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/email/exists',
            query: {
                'email': email,
            },
        });
    }
    /**
     * Check if email or username exists.
     * @returns EmailOrUsernameExists Success
     * @throws ApiError
     */
    public emailOrUsernameExistsUser({
        emailOrUsername,
    }: {
        emailOrUsername: string,
    }): CancelablePromise<EmailOrUsernameExists> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/email-or-username/exists',
            query: {
                'emailOrUsername': emailOrUsername,
            },
        });
    }
    /**
     * Delete all user sessions older than a certain time
     * Deletes all user sessions older than a certain time
     *
     * - Allowed roles: app_cron
     * @returns UserSessionDeleteResult Success
     * @throws ApiError
     */
    public cleanupUserSessionsUser({
        daysOld,
    }: {
        /**
         * Delete all user sessions older than a certain time
         */
        daysOld: number,
    }): CancelablePromise<UserSessionDeleteResult> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/user/sessions',
            query: {
                'daysOld': daysOld,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
}
